import { Link } from "gatsby"
import PropTypes from "prop-types"
import React , {Component} from "react"
import Image from "../components/image"
import "../assets/header.css"
import firebase from "../components/firebase"
import Logo from "../images/dark-horse.png"
class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // function to handle click
  handleClick() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  

render(){

  const menuActive = this.state.showModal ? 'phone-menu-toggle' : '';
  const dropdownOptions = this.state.showModal ? 'mobile-menu-show' : ''
  
  return(
    <header>

      <Link to="/">
        <div className="mobile-logo-container">
          <img alt="Logo" src={Logo}/>
        </div>
      </Link>

      <nav>

        <div onClick={this.handleClick} className={`dropdown-icon-wrapper phone-menu-toggle-two ${menuActive}`}>
          <Image alt="Logo" filename="menu-white.png"/>
        </div>

        <ul className="desktop-menu-options ">
          <Link to="/menu"><li>Menu</li></Link>
          <Link to="/blog"><li>Blog</li></Link>
          <Link to="/events"><li>Events</li></Link>
          <Link to="/gallery"><li>Gallery</li></Link>
          <Link to="/testimonials"><li>Testimonials</li></Link>
          <Link to="/contact_us"><li>Contact Us</li></Link>
          <Link to="/coursepayment"><li>Cooking Course</li></Link>
        </ul>
        
        <div className={`mobile-menu-options ${dropdownOptions}`}>
          
          <ul>
            <Link onClick={this.handleClick} to="/menu">
            <li>Menu</li>
            </Link>
            <Link onClick={this.handleClick} to="/events">
              <li>Events</li>
            </Link>
            <Link onClick={this.handleClick} to="/contact_us">
              <li>Contact Us</li>
            </Link>
            <Link onClick={this.handleClick} to="/testimonials">
              <li>Testimonials</li>
            </Link>
            <Link onClick={this.handleClick} to="/gallery">
              <li>Gallery</li>
            </Link>
            <Link onClick={this.handleClick} to="/blog">
              <li>Blog</li>
            </Link>
            <Link onClick={this.handleClick} to="/coursepayment">
              <li>Cooking Course</li>
            </Link>
          </ul>
          
        </div>
        
      </nav>
    </header>
 )
}

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
