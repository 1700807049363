
import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from 'react-helmet';
import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({children}) => {

  const [showIframe, setShowIframe] = useState(false);

  const handleClick = () => {
    setShowIframe(!showIframe);
};




  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <div className="stammer">
          <button
            className={`floating-circle ${showIframe ? 'floating-circle-open stammerOpen' : ''}`} 
            onClick={handleClick}>
            {showIframe ? 'Close' : 'Need Help?'}
          </button>

          {showIframe && (
            <iframe 
              className="stammerIframe"
              src="https://app.stammer.ai/en/chatbot/embed/090caec6-e22a-4da8-a3e9-d1a562c411fa" 
              width="100%"
              height="600"
              frameborder="0" 
            ></iframe>
          )}

        </div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
