import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/footer.css"

const Footer = () => (
  <div className="footer_container">
    <footer>

      <div className="other_contacts_footer">
        <p className="contact-us-header">Contact Us</p>
        <a href="mailto:darkhorsepastries@gmail.com">darkhorsepastries@gmail.com</a>
        <a href="tel:630-570-0347">630-570-0347</a>        
      </div>
      <div className="footer_social_media">
        <a href="https://www.instagram.com/therealdarkhorse/">
          <img  src="https://user-images.githubusercontent.com/26069518/97885300-cbae7600-1cec-11eb-926d-f387546b5baf.png"/>
        </a>
        <a href="https://www.youtube.com/channel/UCHARp8DUNpL9P7znieMy8Pg/videos">    
          <img  src="https://user-images.githubusercontent.com/26069518/97885670-50998f80-1ced-11eb-9d59-ee75df5126e1.png"/>  
        </a>
        <a href="https://www.facebook.com/DarkHorsePastries">
          <img  src="https://user-images.githubusercontent.com/26069518/97885912-95252b00-1ced-11eb-9109-1faa980c635b.png"/>
        </a>
        <a href="https://www.snapchat.com/add/blackvelvet1976">
          <img  src="https://user-images.githubusercontent.com/26069518/97886387-28f6f700-1cee-11eb-9339-8ea4c314c887.png"/>
        </a>
      </div>

      <div>
        <p>© {new Date().getFullYear()} Dark Horse Catering </p>
        <a className="maintained_by" href="https://davidpina.dev">Maintained by: <span>David Pina</span></a>
      </div>

    </footer>
  </div>
)

export default Footer