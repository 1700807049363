import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';  // <----

const firebaseConfig = {
  apiKey: "AIzaSyDRc2nhXdRd4mLlD6_pXUKKriOUF6019Z0",
  authDomain: "dark-horse-d25f4.firebaseapp.com",
  databaseURL: "https://dark-horse-d25f4.firebaseio.com",
  projectId: "dark-horse-d25f4",
  storageBucket: "dark-horse-d25f4.appspot.com",
  messagingSenderId: "528151596651",
  appId: "1:528151596651:web:cb5d20c0bb26e20d87eb2e",
  measurementId: "G-XX4K45341Q"
};

if (typeof window !== `undefined`) {
  firebase.initializeApp(firebaseConfig)  
}

export default firebase;
